<template>
  <div class="detail-wrap">
    <div class="detail-title">
      Transaction Details - <span style="color:#7B70F0;">{{ $route.query.id }}</span>
    </div>
    <b-card
      class="mt-2"
      title="Basic Info"
    >
      <hr>
      <b-row class="mt-2">
        <b-col
          v-for="(item, index) in infos"
          :key="index"
          md="4"
          class="mb-2"
        >
          <b-row>
            <b-col
              md="6"
              class="info-key"
            >
              {{ item.key }}
            </b-col>
            <b-col
              v-if="item.key === 'Merchant ID'"
              md="6"
              class="info-value"
            >
              <b-link
                :to="{
                  path: '/merchant-detail',
                  query: { id: item.value, timezone: timeZone },
                }"
                class="font-weight-bold cursor-pointer"
                target="_blank"
              >
                {{ item.value }}
              </b-link>
            </b-col>
            <b-col
              v-else-if="item.key === 'Attachment'"
              md="6"
              class="info-value"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                size="sm"
                class="cursor-pointer"
                @click="viewImage"
              >
                View
              </b-button>
            </b-col>
            <b-col
              v-else
              md="6"
              class="info-value"
            >
              {{ item.value }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Financial Info"
    >
      <hr>
      <b-row class="mt-2">
        <b-col
          v-for="(item, index) in financialInfos"
          :key="index"
          md="4"
          class="mb-2"
        >
          <b-row>
            <b-col
              md="6"
              class="info-key"
            >
              {{ item.key }}
            </b-col>
            <b-col
              md="6"
              class="info-value"
            >
              {{ item.value }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Payer Bank Account Info"
    >
      <hr>
      <b-row class="mt-2">
        <b-col
          v-for="(item, index) in bankAccountInfos"
          :key="index"
          md="4"
          class="mb-2"
        >
          <b-row>
            <b-col
              md="6"
              class="info-key"
            >
              {{ item.key }}
            </b-col>
            <b-col
              md="6"
              class="info-value"
            >
              {{ item.value }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Beneficiary Bank Account Info"
    >
      <hr>
      <b-row class="mt-2">
        <b-col
          v-for="(item, index) in beneficiaryBankAccountInfos"
          :key="index"
          md="4"
          class="mb-2"
        >
          <b-row>
            <b-col
              md="6"
              class="info-key"
            >
              {{ item.key }}
            </b-col>
            <b-col
              md="6"
              class="info-value"
            >
              {{ item.value }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="History"
    >
      <hr>
      <table-list
        :table-data.sync="tableData"
        :list.sync="tableList"
        :page-num.sync="currentPage"
        :total-list.sync="totalList"
      />
      <b-button
        class="operation"
        variant="primary"
        @click="operation"
      >Operation</b-button>
      <b-button
        class="attachments"
        variant="primary"
        @click="attachments"
      >Attachments</b-button>
    </b-card>

    <b-modal
      ref="image-modal"
      :no-close-on-backdrop="true"
      title=""
      cancel-variant="outline-secondary"
      size="lg"
      hide-footer
      centered
    >
      <b-img
        :src="modalImage"
        fluid
        alt="Responsive image"
        style="cursor: pointer;"
        @click="$previewPictrue(modalImage)"
      />
    </b-modal>

    <!-- modal -->
    <b-modal
      ref="manage-this-deposit"
      title="Manage This Deposit"
      ok-only
      ok-title="Submit"
      footer-class="manage-this-footer"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        class="mt-1 pl-2 pr-2"
        @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col
            cols="12"
            sm="12"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">Status</label>
              </div>
              <v-select
                v-model="manageStatus"
                :options="statusOption"
                :clearable="false"
              />
            </b-form-group></b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="12"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">Processing Fee</label>
              </div>
              <b-input-group prepend="$">
                <b-form-input
                  v-model="manageProcessingFee"
                  autocomplete="off"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="12"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">Amount received</label>
              </div>
              <b-input-group>
                <b-form-input
                  v-model="manageAmountReceived"
                  autocomplete="off"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="12"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">Remark</label>
              </div>
              <b-form-textarea
                v-model="manageRemark"
                autocomplete="off"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="12"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">Password</label>
              </div>
              <b-input-group>
                <b-form-input
                  v-model="managePassword"
                  :type="passwordFieldType"
                  autocomplete="off"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-modal
      ref="manage-attachment"
      title="Attachment"
      ok-title="Submit"
      footer-class="manage-this-footer"
      @hidden="resetAttachmentModal"
      @ok="handleAttachmentOk"
    >
      <b-form
        @submit.prevent
      >
        <p
          class="mb-2"
          style="color: #333333;"
        >
          Please upload files associated with the merchant below:
        </p>
        <b-form-group>
          <b-form-file
            placeholder=""
            accept=".pdf,.jpg,.jpeg,.bmp"
            browse-text="Upload"
          />
          <b-form-text style="color: #AAAAAA;">
            Format required: PDF, JPG, JPEG, BMP, no more than 10MB
          </b-form-text>
        </b-form-group>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import {
  defineComponent, getCurrentInstance, ref, computed, onMounted,
} from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BImg,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BImg,
    vSelect,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormFile,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const { $store } = proxy
    // const infos = ref([
    //   { key: 'Transaction ID', value: 'SGDH23476436482DDFHS' },
    //   { key: 'Merchant Name', value: 'TEST1234' },
    //   { key: 'Status', value: 'PROCESSING' },
    //   { key: 'Date Submitted', value: '2023-04-12 14:34' },
    //   { key: 'Remark', value: 'TEST TRANSACTION' },
    //   { key: 'Transaction Date', value: '2023-04-12 14:34' },
    //   { key: 'Merchant ID', value: 'AIUYSCGAUYGD128497' },
    //   { key: 'Attachment', value: '' },
    //   { key: 'Admin Remark', value: '' },
    // ])
    // const financialInfos = ref([
    //   { key: 'Amount Submitted', value: '100' },
    //   { key: 'Processing Fee', value: '0' },
    //   { key: 'Currency', value: 'BRL' },
    //   { key: 'Amount Recieved', value: '100' },
    // ])
    // const bankAccountInfos = ref([
    //   { key: 'Payer', value: '1546546' },
    //   { key: 'Transfersmile Wallet', value: '1546546' },
    // ])
    // const beneficiaryBankAccountInfos = ref([
    //   { key: 'Bank Country', value: 'Brazil' },
    //   { key: 'Bank Name', value: 'Bank of Brazil' },
    //   { key: 'Account Name', value: 'Spending brl' },
    //   { key: 'Bank Account Number', value: '1546546' },
    //   { key: 'Address', value: 'AGDG Road, Sao Paulo' },
    //   { key: 'Bank Branch', value: 'ABC' },
    //   { key: 'SWIFT Code', value: 'SHS458' },
    //   { key: 'Currency', value: 'BRL' },
    // ])
    const modalImage = ref('')
    const viewImage = () => {
      // 显示图片
      proxy.$refs['image-modal'].show()
      modalImage.value = 'https://picsum.photos/1024/400/?image=41'
    }
    const timeZone = computed(() => $store.state.wallet.timeZone, { immediate: true, deep: true })

    // history
    const currentPage = ref(1)
    const totalList = ref(0)
    const tableData = ref({
      tableColumns: [
        { key: 'status', label: 'Status' },
        { key: 'date', label: 'Date' },
        { key: 'description', label: 'Description' },
      ],
      templates: [
        { key: 'date', tType: 'datetime' },
      ],
    })

    const operation = () => {
      proxy.$refs['manage-this-deposit'].show()
    }
    const attachments = () => {
      proxy.$refs['manage-attachment'].show()
    }
    const statusOption = ref([
      { label: 'FAILED', value: 'FAILED' },
      { label: 'REJECTED', value: 'REJECTED' },
    ])
    const manageStatus = ref('')
    const manageProcessingFee = ref('')
    const manageAmountReceived = ref('')
    const manageRemark = ref('')
    const managePassword = ref('')
    const resetModal = () => {
      manageStatus.value = ''
      manageProcessingFee.value = ''
      manageRemark.value = ''
      managePassword.value = ''
      manageAmountReceived.value = ''
    }
    const handleOk = () => {}

    const depositDetail = ref({})
    onMounted(() => {
      setTimeout(() => {
        depositDetail.value = {
          basic_info: {
            transaction_id: '1', // string
            merchant_name: '2', // string
            merchant_sn: '3', // string
            transaction_status: 0, // 1 成功, 2 失败, 3 处理中 number
            created_at: '4', // string
            transaction_at: '5', // string
            remark: '6', // string
            admin_remark: '7', // string
          },
          financial_info: {
            amount_submitted: '8', // string
            amount_re: '9', // string
            precessing_fee: '0', // string
            currency: '1', // string
          },
          payer_info: {
            bank_country: '2', // string
            bank_name: '3', // string
            account_name: '4', // string
            bank_account_number: '5', // string
            address: '6', // string
            bank_branch: '7', // string
            swift_code: '8', // string
            currency: '9', // string
          },
          payee_info: {
            bank_country: '0', // string
            bank_name: '1', // string
            account_name: '2', // string
            bank_account_number: '3', // string
            address: '4', // string
            bank_branch: '5', // string
            swift_code: '6', // string
            currency: '7', // string
          },
          serial_list: [
            {
              serial_id: '8', // string
              creation_data: '9', // string
              amount: '0', // string
              arrival_amount: '1', // string
            },
          ],
        }
      }, 500)

      // proxy.$showLoading()
      // const res = await proxy.$api.fetchMerchantTransactionDetail({ merchant_id: proxy.$route.query.id }).catch(() => { proxy.$hideLoading() })
      // proxy.$hideLoading()
      // if (!res) { return }
      // const { code, data, message } = res.data
      // if (code === 200) {
      //   console.log(data)
      // } else {
      //   proxy.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Notification',
      //       icon: 'BellIcon',
      //       text: `${message}`,
      //       variant: 'danger',
      //     },
      //   }, {
      //     position: 'top-center',
      //   })
      // }
    })

    const infos = computed(() => {
      console.log(depositDetail)
      if (!depositDetail.value.basic_info) {
        return []
      }
      const {
        transaction_id, merchant_name, transaction_status, created_at, remark, transaction_at, admin_remark,
      } = depositDetail.value.basic_info
      return [
        { key: 'Transaction ID', value: transaction_id },
        { key: 'Merchant Name', value: merchant_name },
        { key: 'Status', value: transaction_status },
        { key: 'Date Submitted', value: created_at },
        { key: 'Remark', value: remark },
        { key: 'Transaction Date', value: transaction_at },
        { key: 'Merchant ID', value: '' },
        { key: 'Attachment', value: '' },
        { key: 'Admin Remark', value: admin_remark },
      ]
    })
    const financialInfos = computed(() => {
      console.log(depositDetail)
      if (!depositDetail.value.financial_info) {
        return []
      }
      const {
        amount_submitted, precessing_fee, currency, amount_re,
      } = depositDetail.value.financial_info
      return [
        { key: 'Amount Submitted', value: amount_submitted },
        { key: 'Processing Fee', value: precessing_fee },
        { key: 'Currency', value: currency },
        { key: 'Amount Recieved', value: amount_re },
      ]
    })
    const bankAccountInfos = computed(() => {
      console.log(depositDetail)
      if (!depositDetail.value.payer_info) {
        return []
      }
      // const {} = depositDetail.value.payer_info
      return [
        { key: 'Payer', value: '1546546' },
        { key: 'Transfersmile Wallet', value: '1546546' },
      ]
    })
    const beneficiaryBankAccountInfos = computed(() => {
      console.log(depositDetail)
      if (!depositDetail.value.payee_info) {
        return []
      }
      const {
        bank_country, bank_name, account_name, bank_account_number, address, bank_branch, swift_code, currency,
      } = depositDetail.value.payee_info
      return [
        { key: 'Bank Country', value: bank_country },
        { key: 'Bank Name', value: bank_name },
        { key: 'Account Name', value: account_name },
        { key: 'Bank Account Number', value: bank_account_number },
        { key: 'Address', value: address },
        { key: 'Bank Branch', value: bank_branch },
        { key: 'SWIFT Code', value: swift_code },
        { key: 'Currency', value: currency },
      ]
    })
    const tableList = computed(() => {
      console.log(depositDetail)
      if (!depositDetail.value.serial_list) {
        return []
      }
      return [
        {
          status: 'Test Smith', date: '1685604068', description: 'test description',
        },
      ]
    })

    return {
      infos,
      financialInfos,
      bankAccountInfos,
      beneficiaryBankAccountInfos,
      modalImage,
      viewImage,
      timeZone,

      tableData,
      tableList,
      currentPage,
      totalList,

      operation,
      attachments,
      statusOption,

      manageStatus,
      manageProcessingFee,
      manageAmountReceived,
      manageRemark,
      managePassword,

      resetModal,
      handleOk,
    }
  },
  directives: {
    Ripple,
  },
})
</script>

<style lang="scss">
.detail-wrap {
  width: 95%;
  padding: 4rem 0;
  margin: 0 auto;
  min-height: 400px;
  .detail-title {
    font-size: 24px;
  }
  .info-key {
    color: #333333;
  }
  .info-value {
    color: #AAAAAA;
  }
  .operation {
    position: absolute;
    top: 15px;
    right: 200px;
    cursor: pointer;
  }
  .attachments {
    position: absolute;
    top: 15px;
    right: 40px;
    cursor: pointer;
  }
}
  .manage-this-footer {
    justify-content: center;
  }
</style>
